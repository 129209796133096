import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Tooltip, Checkbox, Card, Upload as UploadAntd } from 'antd';
import axios from "axios";
import { io } from "socket.io-client";
import { SettingOutlined } from '@ant-design/icons';

import "./project.css";
import useScrollToTop from '../../utils/useScrollToTop';
import DocumentCard from './DocumentCard';
import { post } from '../../utils/fetch';
import { PageContext, usePageActionHandler } from "../../components/Page";
import { actionTypes } from '../../actions';

export const fileStatus = {
  "1": {text: "[1] Uploading", color: "blue", processing: true },
  "2": {text: "[2] Uploading", color: "blue", processing: true },
  "3": {text: "[3] Extracting", color: "blue", processing: true },
  "4": {text: "[4] Upload failed", color: "red", processing: false },
  "5": {text: "[5] Extracting", color: "blue", processing: true },
  "6": {text: "Ready for conversion", color: "cyan", processing: false },
  "7": {text: "[7] Extracting failed", color: "red", processing: false },
  "8": {text: "[8] Converting", color: "blue", processing: true },
  "9": {text: "[9] Inserting", color: "blue", processing: true },
  "10": {text: "[10] Converting failed", color: "red", processing: false },
  "12": {text: "Finished", color: "green", processing: false }
}

const Project = ({ project, getProject, setProject }) => {
  useScrollToTop()
  const { setError, isActionInProgress } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const { folderId } = useParams();
  const [files, setFiles] = useState(project?.project_files)
  const [includeBoundingBox, setIncludeBoundingBox] = useState(project?.extraction_setting?.includeBoundingBox);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKETIO);
    if (project.id) {
      socket.emit('subscribeToStatusUpdate', `doc2data-${folderId}`);
      socket.on('statusUpdate', async (socketData) => {
        const { data } = socketData;
        if (data.action === 'convertPdfToJsonSuccess') {
          const newProject = await getProject();
          setFiles(newProject.project_files)
        }
        if (data.action === 'convertJsonToExcelUpdate') {
          const newProject = await getProject();
          setFiles(newProject.project_files)
        }
        if (data.action === 'convertJsonToExcelFinished') {
          const newProject = await getProject();
          setFiles(newProject.project_files)
        }
        if (data.action === 'notEnoughCredit') {
          setError("Insufficient credit. Purchase more credit to continue.")
        }
      });
    }

    return () => {
      socket.disconnect();
    };
  }, [project.id]);

  const convertFileToJson = async (id) => {
    const action = "convertFileToJson";
    addAction(action);
    await post(actionTypes[action].api, {
      // adobeClientId: localStorage.getItem("adobeClientId"),
      // adobeClientSecret: localStorage.getItem("adobeClientSecret"),
      projectId: folderId,
      fileId: id
    })
      .then(async (res) => {
        await setFileStatus(id, "5")
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const jsonToExcel = async (fileId) => {
    const action = "convertJsonToExcel";
    addAction(action);
    await post(actionTypes[action].api, {
      openAiKey: localStorage.getItem('openAiApiKey'),
      openAiModel: localStorage.getItem('aiModel'),
      projectId: folderId,
      fileId
    })
      .then(async (res) => {
        const newProject = await getProject();
        setFiles(newProject.project_files)
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const setFileStatus = async (fileId, statusId, err) => {
    const action = "setFileStatus";
    await post(actionTypes[action].api, {
      projectId: project.id,
      fileId,
      statusId,
      err
    })
      .then((res) => {
        setFiles(files => {
          const newFiles = [...files];
          const fileIndex = newFiles.findIndex((item) => item.id === fileId)
          newFiles[fileIndex] = {
            ...newFiles[fileIndex],
            fileStatus: statusId
          }
          return newFiles;
        })
      })
      .catch((err) => {
        setError(err);
      })
  }

  const deleteFile = async (fileId) => {
    const action = "deleteFile";
    addAction(action);
    await post(actionTypes[action].api, {
      projectId: project.id,
      fileId,
    })
      .then((res) => {
        setFiles(files => {
          const newFiles = files.filter((item) => item.id !== fileId)
          return newFiles;
        })
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const updateExtractionSetting = async () => {
    const action = "updateExtractionSetting";
    addAction(action);
    await post(actionTypes[action].api, {
      projectId: project.id,
      includeBoundingBox,
      includePath: !includeBoundingBox
    })
      .then(async () => {
        setProject({
          ...project,
          extraction_setting: {
            includeBoundingBox,
            includePath: !includeBoundingBox
          }
        })
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const getFile = async (fileId) => {
    const action = "getFile";
    return await post(actionTypes[action].api, {
      projectId: project.id,
      fileId,
    })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        setError(err);
      })
  }

  const redoConversion = async (fileId) => {
    const action = "redoConversion";
    addAction(action);
    return await post(actionTypes[action].api, {
      projectId: project.id,
      fileId
    })
      .then(async (res) => {
        await jsonToExcel(fileId)
      })
      .catch((err) => {
        removeAction(action);
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const retry = async (fileId) => {
    const file = await getFile(fileId);
    const fileStatusInt = Number(file.status)
    if (fileStatusInt >= 3 && fileStatusInt < 6) {
      await convertFileToJson(fileId)
    }
    if (fileStatusInt > 6 && file.status !== 12) {
      await jsonToExcel(fileId)
    } else {
      const newProject = await getProject();
      setFiles(newProject.project_files)
    }
  }

  const disableConversionButton = !(files.find((item) => item.fileStatus === "6"))

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <Card style={{ border: 0, backgroundColor: '#c3e3ff', padding: 4 }}>
        <h2 style={{ fontFamily: 'Outfit' }}>Documents</h2>
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: 20, alignItems: 'end', flexDirection: window.innerWidth > 600 ? 'row' : 'column' }}>
          <div>
            {
              files.length === 0 
                ? "Upload your documents."
                : `${files.length} files uploaded, ${files.filter((item) => item.fileStatus === '12').length} finished`
            }
          </div>
          <div style={{ display: 'flex', gap: 5 }}>
            <UploadAntd
              accept='.pdf'
              maxCount={100}
              multiple
              action={async (file) => {
                setFiles((prevFiles) => [...prevFiles, {
                  id: file.uid,
                  uid: file.uid,
                  name: file.name, 
                  fileStatus: "1"
                }])
                const fileName = encodeURIComponent(file.name);
                return `${process.env.REACT_APP_API_ENDPOINT}/create-doc-upload-presigned-url?idToken=${localStorage.getItem("accessToken")}&projectId=${folderId}&uid=${file.uid}&fileName=${fileName}`;
              }}
              headers={{
                authorization: 'authorization-text',
              }}
              showUploadList={false}
              onChange={async (info) => {
                if (info.file.status !== 'uploading') {
                  const { url, fields } = info.file.response;
                  const formData = new FormData();
                  Object.entries({ ...fields, file: info.file.originFileObj }).forEach(([key, value]) => {
                    formData.append(key, value);
                  });
                  await axios.post(url, formData)
                    .then(async () => {
                      await setFileStatus(info.file.uid, "3")
                    })
                    .catch(async (err) => {
                      await setFileStatus(info.file.uid, "4", { err })
                    })

                  await convertFileToJson(info.file.uid)
                }
              }}
            >
              <Button>Upload files</Button>
            </UploadAntd>
            {/* <Button
              style={{ backgroundColor: !disableConversionButton ? 'white': undefined }}
              disabled={!disableConversionButton || isActionInProgress("convertJsonToExcel")}
              type='primary'
              onClick={() => { redoConversion() }}
            >
              Redo conversion
            </Button> */}
            <Button
              style={{ backgroundColor: disableConversionButton ? 'white': undefined }}
              disabled={disableConversionButton || isActionInProgress("convertJsonToExcel")}
              type='primary'
              onClick={() => { jsonToExcel() }}
            >
              Start conversion
            </Button>
            <Tooltip
              placement='bottom'
              arrow={false}
              color="white"
              trigger="click"
              title={
                <div>
                  <Checkbox
                    checked={includeBoundingBox}
                    onChange={(val) => { setIncludeBoundingBox(val.target.checked) }}
                  >
                    Include element coordinates
                  </Checkbox>
                  <div style={{ color: 'black', marginTop: 10 }}>*This is useful for documents with complex layout. Using more AI credits.</div>
                  <Button onClick={updateExtractionSetting} style={{ display: 'flex', marginLeft: 'auto' }} size='small'>Save</Button>
                </div>
              }
            >
              <Button icon={<SettingOutlined />} />
            </Tooltip>
          </div>
        </div>
      </Card>
      <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
        {
          files
            .filter((item) => item.fileStatus !== '12' && item.fileStatus !== '6')
            .map((item) => {
              return (
                <DocumentCard
                  key={item.uid}
                  file={item}
                  extract={convertFileToJson}
                  deleteFile={deleteFile}
                  retry={retry}
                />
              )
            })
        }
        {
          files
            .filter((item) => item.fileStatus === '6')
            .map((item) => {
              return (
                <DocumentCard
                  key={`${item.uid}-6`}
                  file={item}
                  extract={convertFileToJson}
                  deleteFile={deleteFile}
                  retry={retry}
                />
              )
            })
        }
        {
          files
            .filter((item) => item.fileStatus === '12')
            .map((item) => {
              return (
                <DocumentCard
                  key={`${item.uid}-12`}
                  file={item}
                  extract={convertFileToJson}
                  deleteFile={deleteFile}
                  retry={retry}
                  redoConversion={redoConversion}
                />
              )
            })
        }
      </div>
    </div>
  );
}

export default Project;