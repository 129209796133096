import { Route, Switch } from 'react-router-dom';
import React, { createContext, useState } from 'react';

import './App.css';
import routeConfig from './routeConfig';
// import NewVersion from './components/NewVersion';
import routing from './utils/routing';
import { post } from './utils/fetch';
import { actionTypes } from './actions';

const AppContext = createContext(null);

const App = () => {
  const [appState, setAppState] = useState({});

  const getAppSetting = async () => {
    const api = `/get-app-setting`;
    return await post(api)
      .then((res) => {
        const data = res.data;
        setAppState(prev => ({
          ...prev,
          appSetting: data
        }))
        return data;
      })
  }

  const getUser = async () => {
    const action = `getUser`;
    await post(actionTypes[action].api)
      .then((res) => {
        console.log({ res });
        setAppState(prev => ({
          ...prev,
          user: res.data.user,
          features: res.data.features
        }))
      })
  }

  console.log({ appState });

  return (
    <AppContext.Provider
      value={{
        appState,
        appActions: {
          getAppSetting,
          getUser
        },
      }}
    >
      <div className="App">
        <Switch>
          {routeConfig.map((config, index) => (
            <Route
              key={index}
              path={config.route}
              exact={config.components.some(component => component.props && component.props.exact)}
            >
              {routing.renderNestedComponents(config)}
            </Route>
          ))}
        </Switch>
        {/* <NewVersion /> */}
      </div>
    </AppContext.Provider>
  );
}

export { AppContext };
export default App;
