
import ProtectPage from './components/ProtectPage';
import Page from "./components/Page";

import Signin from './pages/auth/Auth';
import Projects from "./pages/projects/Projects"
import AddNewProject from './pages/projects/AddNewProject';
import Project from './pages/project/Project';
import AccountPage from './pages/account/Account';
import SupportPage from './pages/support/Support'
import SchemaExamplePage from './pages/project/SchemaExample';
import Pricing from './pages/pricing/Pricing';

const routeConfig = [{
  route: "/",
  components: [
    ProtectPage,
    { component: Page },
    { component: Projects, props: { exact: true } }
  ]
}, {
  route: "/signin",
  components: [
    { component: Signin, props: { exact: true } }
  ]
}, {
  route: "/pricing",
  components: [
    { component: Page },
    { component: Pricing, props: { exact: true } }
  ]
}, {
  route: "/support",
  components: [
    { component: SupportPage, props: { exact: true } }
  ]
}, {
  route: "/user/folders",
  components: [
    ProtectPage,
    { component: Page },
    { component: Projects, props: { exact: true } }
]},  {
  route: "/example/extraction-schema",
  components: [
    { component: Page },
    { component: SchemaExamplePage, props: { exact: true } }
]}, {
  route: "/user/folder/new",
  components: [
    ProtectPage,
    Page,
    { component: AddNewProject, props: { exact: true } }
]}, {
  route: "/user/folder/:folderId",
  components: [
    ProtectPage,
    { component: Page, props: { initialActions: ["getUser"] } },
    { component: Project, props: { exact: true } }
]}, {
  route: "/user/account",
  components: [
    ProtectPage,
    { component: Page, props: { initialActions: ["getUser"] } },
    { component: AccountPage, props: { exact: true } }
]}]

export default routeConfig;
