import React, { useContext, useEffect, useState } from 'react';
import { Button, Card } from 'antd';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import "./index.css";
import useScrollToTop from '../../utils/useScrollToTop';
import ProjectCard from './ProjectCard';
import MainMenu from '../../components/MainMenu';
import { post } from '../../utils/fetch';
import { PageContext, usePageActionHandler } from "../../components/Page";
import { actionTypes } from '../../actions';
import Logo from '../../components/Logo';

const Project = () => {
  useScrollToTop()
  const { setError, withConfirmation } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getProjects();
  }, [])

  const getProjects = async () => {
    const action = "getProjects";
    addAction(action);
    await post(actionTypes[action].api)
      .then((res) => {
        setProjects(res.data.projects)
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const deleteProject = (projectId) => withConfirmation("deleteProject",
    async () => {
      const action = "deleteProject";
      addAction(action);
      await post(actionTypes[action].api, {
        projectId
      })
        .then(() => {
          const updatedProjects = [...projects].filter((item) => item.id !== projectId);
          setProjects(updatedProjects)
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          removeAction(action);
        })
  })

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: 10 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', height: 40, alignItems: 'center', margin: '0 2px' }}>
        <Link to="/">
          <Logo color="black" width={23} height={23} />
        </Link>
        <MainMenu />
      </div>
      <Card style={{ border: 0, backgroundColor: '#c3e3ff', padding: 4, marginTop: 6 }}>
        <h2 style={{ fontFamily: 'Outfit' }}>Folders</h2>
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: 20, alignItems: 'end', flexDirection: window.innerWidth > 600 ? 'row' : 'column' }}>
          <div style={{ display: 'flex', gap: 10, alignItems: 'baseline', flexDirection: window.innerWidth > 600 ? 'row' : 'column' }}>
            <span style={{ maxWidth: 900 }}>
              Organize your documents into different folders.
            </span>
          </div>
          <Link to="/user/folder/new">
            <Button>
              Add new folder
            </Button>
          </Link>
        </div>
      </Card>
      <div style={{ display: 'flex', gap: 5, flexDirection: 'column', maxHeight: 'calc(100vh - 124px - 70px - 32px)', overflowY: 'auto' }}>
        {
          projects?.map((item) => {
            return (
              <ProjectCard project={item} deleteProject={deleteProject} />
            )
          })
        }
      </div>
    </div>
  );
}

export default Project;
