import { Input } from "antd";
import React, { useContext } from 'react';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import "./index.css"
import { PageContext } from '../../components/Page';
import { post } from "../../utils/fetch";
import { actionTypes } from "../../actions";
import { usePageActionHandler } from "../../components/Page";
import TabEditableCard from "../../components/TabEditableCard";

const AddNewProject = () => {
  const {
    isActionInProgress,
    setError
  } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const history = useHistory();

  const createProject = async (values) => {
    const action = "createProject";
    addAction(action, actionTypes[action].fetchMessage)
    await post(actionTypes[action].api, {
      name: values.name,
      description: values.description
    })
      .then((res) => {
        history.push(`/user/folder/${res.data.id}`)
      })
      .catch((err) => {
        setError(err)
      })
    removeAction(action)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
      {/* <Menu /> */}
      <div className="studio-add-new-project-root">
        <h2 style={{ textAlign: 'center', margin: window.innerWidth > 600 ? '50px 0' : '20px 0' }}>
          Add new folder
        </h2>
        <div className="add-new-project-form">
          <TabEditableCard
            editable
            formOnly
            allowClose
            unstyle
            onClose={() => { history.push("/user/folders") }}
            onSubmit={createProject}
            isSubmitting={isActionInProgress("createProject")}
            allowReset={false}
            submitText="Create"
            form={{
              initialValues: {
                name: null,
                description: null
              }
            }}
            data={[
              {
                name: 'name',
                label: "Name",
                input: Input
              }
            ]}
          />
        </div>
      </div>
    </div>
  )
}

export default AddNewProject;
