import React from 'react';
import { Button, Card } from 'antd';
import { SyncOutlined } from "@ant-design/icons";

import "./project.css";
import { fileStatus } from './Document';
import CustomTag from "../../components/CustomTag";
import { convertToLocaleTime } from "../../utils/date";

const DocumentCard = ({ file, deleteFile, retry, redoConversion }) => {
  const fileStatusInt = Number(file.fileStatus);
  return (
    <div style={{ display: 'flex', gap: 5 }}>
      <Card size='small' style={{ minWidth: 200 }}>
        <div style={{ textAlign: 'center', width: '100%' }}>{convertToLocaleTime(file.updated_date || new Date())}</div>
      </Card>
      <Card size='small' style={{ width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: window.innerWidth > 600 ? 'row' : 'column', gap: 20 }}>
            <span>{file.name}</span>
            <span style={{ display: 'flex', justifyContent: 'end', gap: 5, flexWrap: 'wrap' }}>
              {
                fileStatusInt === 6 && (
                  <Button size='small' onClick={() => { deleteFile(file.id) }}>
                    Delete
                  </Button>
                )
              }
              {
                fileStatusInt < 3 && (
                  <Button size='small' onClick={() => { deleteFile(file.id) }}>
                    Cancel 
                  </Button>
                )
              }
              {
                fileStatusInt >= 3 && fileStatusInt !== 12 && fileStatusInt !== 6 && (
                  <Button size='small' onClick={() => { retry(file.id) }}>
                    Retry
                  </Button>
                )
              }
              {/* {
                fileStatusInt === 12 && (
                  <Button size='small' onClick={() => { redoConversion(file.id) }}>
                    Redo
                  </Button>
                )
              } */}
              <CustomTag
                style={{ height: 24 }}
                color={file.fileStatus ? fileStatus[file.fileStatus].color : "blue"}
                icon={fileStatus[file.fileStatus].processing ? <SyncOutlined spin /> : null}
              >
                {file.fileStatus ? fileStatus[file.fileStatus].text : "processing"}
              </CustomTag>
            </span>
          </div>
        </div>
      </Card>
    </div>

  )
}

export default DocumentCard;
