import React from 'react';
import { Card } from 'antd';

import useScrollToTop from '../../utils/useScrollToTop';
import CommonPage from '../../components/CommonPage';

const SchemaExample = () => {
  useScrollToTop()

  return (
    <CommonPage title="Extraction Schema Examples">
      <Card>
        <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
          <div>
            <h2>Basic Extraction</h2>
            <a href="/basic_extraction_schema.png">
              <img
                style={{ maxWidth: 900, width: '100%', borderRadius: 10, boxShadow: "rgba(0, 0, 0, 0.2) 1px 2px 6px 0px" }}
                alt="basic_extraction_schema"
                src="/basic_extraction_schema.png"
              />
            </a>
          </div>
          <div>
            <h2>Extraction with Advance Analysis</h2>
            <a href="/advance_analysis_schema.png">
              <img
                style={{ maxWidth: 900, width: '100%', borderRadius: 10, boxShadow: "rgba(0, 0, 0, 0.2) 1px 2px 6px 0px" }}
                alt="advance_analysis_schema"
                src="/advance_analysis_schema.png"
              />
            </a>
          </div>
        </div>
      </Card>
    </CommonPage>
  );
}

export default SchemaExample;
