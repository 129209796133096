import { Alert, Button, Card } from "antd";
import { convertToLocaleTime } from "../../utils/date";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useContext } from "react";
import { AppContext } from "../../App";

const Download = ({ lastUpdated, downloadExcel }) => {
  const { appState } = useContext(AppContext);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <Card style={{ border: 0, backgroundColor: '#c3e3ff', padding: 4 }}>
        <h2 style={{ fontFamily: 'Outfit' }}>Download</h2>
        <div style={{ minHeight: 32, display: 'flex', justifyContent: 'space-between', gap: 20, alignItems: 'end', flexDirection: window.innerWidth > 600 ? 'row' : 'column' }}>
          <div>
            {
              lastUpdated
                ? `Last updated ${convertToLocaleTime(lastUpdated)}`
                : `Your output file will appear here once it's generated.`
            }
          </div>
        </div>
      </Card>
      <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
        {
          appState?.user?.plan_id !== 'v1_sub'
          && appState?.user?.plan_id !== 'trial'
          && (
            <Alert
              style={{ marginBottom: 20 }}
              description={
                <div style={{ display: 'flex', gap: 20, alignItems: 'center', justifyContent: 'center' }}>
                  <div style={{ fontSize: 16, fontWeight: 600, textAlign: 'center' }}>Not satisfied with the output? Try a more advanced AI model for only $1.</div>
                  <Link to="/pricing"><Button type="primary">View pricing</Button></Link>
                </div>
              }
            />
          )
        }
        <div style={{ display: 'flex', gap: 5 }}>
          <Card size='small' style={{ width: '100%' }}>
            <div style={{ height: 22, padding: '0 7px' }}>
              Excel (.xlsx)
            </div>
          </Card>
          <Card size='small'>
            <div style={{ display: 'flex', gap: 5 }}>
              <Button size='small' type='link' disabled={!lastUpdated} onClick={downloadExcel}>Download</Button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default Download;
