import React, { useContext, useState } from 'react';
import { Button, Card, InputNumber } from 'antd';

import useScrollToTop from '../../utils/useScrollToTop';
import CommonPage from '../../components/CommonPage';
import { PageContext, usePageActionHandler } from '../../components/Page';
import { actionTypes } from '../../actions';
import { post } from '../../utils/fetch';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const plans = [
  {
    id: 'trial',
    title: "Trial",
    price: 1,
    desc: "Try all our premium features for just $1.",
    features: [
      "Single folder",
      "50 pages of multiple documents upload",
      "500 AI credits",
      "Access to Advance Analysis feature",
      "Access to Advance AI Model (GPT-4)"
    ],
    purchaseBtnText: "Buy"
  }, {
    id: "v1_sub",
    title: "Subscription",
    price: "29 / month",
    desc: "Unlock the full potential of our services with our subscription plan. Designed for users who require more extensive capabilities, this plan offers:",
    features: [
      "Unlimited folders",
      "500 pages of multiple documents upload / month",
      "6000 AI credits / month",
      "Access to Advance Analysis feature",
      "Access to Advance AI Model (GPT-4)",
      "Use your own OpenAI API key (Optional)"
    ],
    purchaseBtnText: "Subscribe"
  }
]

const Pricing = () => {
  useScrollToTop()
  const history = useHistory()
  const {
    setError
  } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const userEmail = localStorage.getItem('email');
  const [docCredit, setDocCredit] = useState(500);
  const [aiCredit, setAiCredit] = useState(1000);

  const handleChangeDocCredit = (newValue) => {
    if (newValue < 500) {
      return newValue;
    }
    if (newValue % 100 === 0) {
      setDocCredit(newValue)
    } else {
      setDocCredit(newValue + (100 - (newValue % 100)))
    }
  };

  const handleChangeAiCredit = (newValue) => {
    if (newValue < 1000) {
      return newValue;
    }
    if (newValue % 200 === 0) {
      setAiCredit(newValue)
    } else {
      setAiCredit(newValue + (200 - (newValue % 200)))
    }
  };

  const handleGenerateBuyTokenPaymentLink = async (productKey, value) => {
    const action = 'createTokenPaymentLink';
    addAction(action);
    await post(actionTypes[action].api, {
      productKey,
      amount: value * 100,
      url: window.location.protocol + '//' + window.location.host
    })
      .then((res) => {
        window.location.replace(`${res.data.paymentLink}?prefilled_email=${encodeURIComponent(userEmail)}`)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        removeAction(action);
      })
  }

  return (
    <CommonPage title="Pricing">
      <div style={{ display: 'flex', gap: 10, flexWrap: window.innerWidth > 1080 ? undefined : 'wrap' }}>
        {
          plans.map((plan) => (
            <Card style={{ width: '100%', height: '100%', minWidth: 300 }}>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <div key={plan.id}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h2>{plan.title}</h2>
                    <h2>${plan.price}</h2>
                  </div>
                  <p>{plan.desc}</p>
                  <ul>
                    {
                      plan.features.map((feature) => (
                        <li>{feature}</li>
                      ))
                    }
                  </ul>
                </div>
                <Button
                  onClick={() => {
                    if (localStorage.getItem("accessToken")) {
                      handleGenerateBuyTokenPaymentLink(plan.id)
                    } else {
                      history.push("/")
                    }
                  }}
                  style={{ display: 'flex', marginLeft: 'auto', marginTop: 30 }}
                >
                  {plan.purchaseBtnText}
                </Button>
              </div>
            </Card>
          ))
        }
        <Card style={{ width: '100%', minWidth: 300 }}>
          <div>
              <h2>Extra Credits</h2>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3>Page Credits</h3>
                <h3>${docCredit / 100}</h3>
              </div>
              <p>
                Used to upload your documents. 1 credit for 1 page. Priced at $5 for 500 pages.
              </p>
              <div style={{ display: 'flex', alignItems: 'baseline', gap: 5 }}>
                <InputNumber
                  style={{ width: '100%' }}
                  defaultValue={docCredit}
                  value={docCredit}
                  step={500}
                  onChange={handleChangeDocCredit}
                />
                <Button
                  style={{ display: 'flex', marginLeft: 'auto' }}
                  onClick={() => {
                    if (localStorage.getItem("accessToken")) {
                      handleGenerateBuyTokenPaymentLink('doc_credit', docCredit / 100)
                    } else {
                      history.push("/")
                    }
                  }}
                >
                  Buy
                </Button>
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3>AI credits</h3>
                <h3>${aiCredit / 200}</h3>
              </div>
              <p>
                Used for the AI to analyze your documents. Each page of a document takes around 10-20 credits depending on the amount of text and the complexity of the layout. Priced at $5 for 1000 credits.
              </p>
              <div style={{ display: 'flex', alignItems: 'baseline', gap: 5 }}>
                <InputNumber
                  style={{ width: '100%' }}
                  step={1000}
                  onChange={handleChangeAiCredit}
                  value={aiCredit}
                  defaultValue={aiCredit}
                />
                <Button
                  style={{ display: 'flex', marginLeft: 'auto' }}
                  onClick={() => {
                    if (localStorage.getItem("accessToken")) {
                      handleGenerateBuyTokenPaymentLink('ai_credit', aiCredit / 200)
                    } else {
                      history.push("/")
                    }
                  }}
                >
                  Buy
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </CommonPage>
  );
}

export default Pricing;